// will change rules for performance
.main{
  will-change: transform;
}
.nav-menu{
  .bg-staggered,
  .bg-flower,
  .nav-menu__links{
    will-change: transform;
  }
}

.nav-menu{
  $this: &;
  position: fixed;

  z-index: 999;
  top: 0;
  left: 0;

  height: 100%;

  // side bar navigation
  &__header{
    position: relative;
    z-index: 999;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    padding: 20px 5px;

    background: white;

    &:after{
      content: '';

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      display: block;
      height: 100%;
      width: 1px;

      background: $gray;
      opacity: 0.5;

      max-height: 0;

      transition: max-height 550ms $easeInOutQuart;
      transition-delay: 200ms;
    }

    .logo-box{
      img{
        max-width: 100%;
        height: auto;
      }
    }

    .menu-icon{
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 80%;
    }

    .sm-icons{
      height: 10%;
      ul{
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li{
          margin: 0 5px;
        }
      }

      img{
        max-height: 30px;
      }
    }
  }

  .logo-box{
    flex-grow: 0;
    flex-shrink: 0;
  }

  // main content
  &__content{
    position: absolute;
    top: 0;

    height: 100%;
    overflow: hidden;
    pointer-events: none;

    opacity: 0;
  }

  // staggered background for transition
  .bg-staggered{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: scaleX(0);
    transform-origin: left;

    &[data-order='1']{ background: $aqua-haze; }
    &[data-order='2']{ background: white; }
  }

  // animated background flower
  .bg-flower{
    position: absolute;
    top: -10%;
    right: -10%;

    width: 60%;
    min-width: 500px;

    transform-origin:  100% 0%;

    opacity: 0;

    img{
      width: 100%;
      height: auto;
    }
  }

  // nav-links
  &__links{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    height: 85%;
    // border: 1px solid red; //temp

    padding: 2% 5%;

    // main nav links
    ul{
      // border: 1px solid blue; //temp
      padding: 0;
      list-style: none;
      margin: 0;

      li{
        font-size: $h2-font-size;
        // margin: 5% auto;
        margin: 1% auto;
        color: $primary-color;
        opacity: 0;
      }
    }

    // disclaimer and shit
    ul:not(:first-child){
      li{
        margin: 5px auto;
        font-size: $h6-font-size;
        color: $primary-color;
      }
    }
  }

  // nav footer
  &__footer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    padding: 0 5%;
    padding-bottom: 20px;

    height: 15%;

    opacity: 0;

    p{
      margin: 0;
      &:before{
        content: '';

        display: block;
        width: 100%;
        height: 1px;

        margin: 5px auto;

        background: $gray;
      }
    }

    span{
      padding-left: 10px;
      img{
        max-height: 15px;
      }
    }
  }

  // is-open styling
  &.is-open{
    #{$this}__links,
    #{$this}__footer
    {
      pointer-events: all;
    }

    #{$this}__header{
      &:after{ max-height: 100%; }
    }
  }
}

.inner-list{
  margin-bottom: 0;
  ul{
    padding-left: 20px;
    li{
      font-size: 0.7em !important;
    }
  }
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .nav-menu{
    &__header{
      width: $nav-mobile;

      .sm-icons{
        margin-bottom: 15px;
        ul{
          flex-direction: column;
          li{
            text-align: center;
            margin: 5px 0;
          }
        }
      }
    }

    &__content{
      left: $nav-mobile;
      width: calc( 100vw - #{$nav-mobile} );
    }
  }
}

.container-property-type {
   width: 125% !important;
}

span.property-type {
  font-size: 20px;
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){
  .nav-menu{
    &__header{
      width: $nav-desktop;
    }

    &__content{
      left: $nav-desktop;
      width: calc( 100vw - #{$nav-desktop} );
    }
  }
}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
