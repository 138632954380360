form{
  .input-group{
    display: flex;
    flex-direction: column;
    margin: 10px auto;

    label{
      text-align: left;
      margin-bottom: 5px;

      color: $gray;
    }

    input, select{
      height: 35px;
      width:  100%;
      padding: 0 5px;

      border: 2px solid $primary-color;
      background: transparent;

      font-size: 1em;
    }
  }
}

$checkbox-size: 20px;
$checkbox-gap: 10px;
$checkbox-weight: 2px;

.checkbox-group{
  position: relative;
  width: 100%;
  margin: 15px auto;

  label{
    width: 100%;

    color: $gray;
    text-align: left;

    cursor: pointer;

    input{
      display: block;
      position: absolute;
      top: 10px;
      left: 4px;
      opacity: 0;
      pointer-events: none;
    }

    & > span{
      display: block;
      float: left;

      span{
        color: $primary-color;
        text-decoration: underline;
      }
    }

    span:nth-of-type(2){
      width: calc( 100% - #{$checkbox-size + $checkbox-gap} );
    }
  }

  &.tac{
    label ~ p{
      text-align: left;

      margin: 0;
      margin-left: 30px;
      color: $gray;

      span{
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}

.custom-checkbox{
  position: relative;
  margin-right: 10px;

  width: $checkbox-size;
  height: $checkbox-size;

  &:before, &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );

    width: 100%;
    height: 100%;

    transition: all 300ms $easeOutQuart;
  }

  &:before{
    width: 100%;
    height: 100%;
    border: 2px solid $primary-color;
  }

  &:after{
    background: $primary-color;

    outline: 2px solid white;
    outline-offset: -4px;

    opacity: 0;
    transform: translate(-50%, -50%) scale(0, 0);
    // background-clip: content-box;
  }

  input:checked + &{
    &:before{ transform: translate(-50%, -50%) scale(0.2, 0.2); }
    &:after{
      opacity: 1;
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }
}
