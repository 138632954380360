.hamburger-box{

  position: relative;
  overflow: hidden;

  .hamburger,.closeburger{ transition: transform $easeOutQuart 400ms; }

  .hamburger{ transform: translateX(0%); }
  .closeburger{ transform: translateX(100%); }

  &.is-open{
    .hamburger{ transform: translateX(-100%); }
    .closeburger{ transform: translateX(0%); }


    $rotate-angle : 45deg;
    .closeburger{
      &:before{ transform: translate( -50%, -50%) rotate(-$rotate-angle); }
      &:after{ transform: translate( -50%, -50%) rotate($rotate-angle); }
    }
  }
}

.hamburger{
  $box-expand    : 5px;
  $line-thickness: 2px;
  $line-width    : 35px; //needs to be at least 35 to fit menu text
  $line-gap      : 6px;

  $line-color    : $gray;
  $anim-delay    : 50ms;
  $anim-duration : 300ms;

  position: relative;
  width: $line-width + ($box-expand*2);
  height: $line-thickness*3 + $line-gap*2 + ($box-expand*2);
  padding: $box-expand;

  color: $line-color;
  overflow: hidden;
  cursor: pointer;

  // animation timing
  span{ transition: transform $anim-duration $easeInOutQuart; }

  // group of lines
  .line-group{
    span{
      display: block;
      width: $line-width;
      height: $line-thickness;

      margin-bottom: $line-gap;
      background: currentColor;
    }

    span:last-child{ width: $line-width/1.6; }

    // anim timing
    span:nth-child(1){ transition-delay: $anim-delay*0; }
    span:nth-child(2){ transition-delay: $anim-delay*1; }
    // span:nth-child(3){ transition-delay: $anim-delay*2; }
  }

  // menu text
  & > span{
    position: absolute;
    top: 0;
    left: 100%;

    font-weight: bold;
  }

  &:hover{
    .line-group{
      span:not(:last-child){ transform: translateX(-100% ) translateX( -$box-expand ); }
      span:last-child{ transform: translateX( $line-width - ($line-width/1.6) ) translateX( $box-expand ); }
    }

    & > span{ transform: translateX(-100%); transition-delay: $anim-delay*2; }
  }
}

.closeburger{
  $line-thickness: 2px;
  $line-width    : 35px; // same as hamburger

  $rotate-angle  : 45deg;

  $line-color    : $gray;
  $anim-delay    : 50ms;
  $anim-duration : 300ms;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  cursor: pointer;

  &:before, &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    width: $line-width;
    height: $line-thickness;

    background: $gray;

    transition: transform $easeOutQuart $anim-duration;
    transform: translate( -50%, -50%);
  }
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .hamburger{
    span{
      left: 88%;
    }
  }

}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){

}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
