// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .main.split{
    $split: '.split';

    #{$split}__left,
    #{$split}__right{
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }

    #{$split}__left{ z-index: 2; }
  }
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){
  .main.split{
    $split: '.split';

    #{$split}__left,
    #{$split}__right{
      width: 50%;
      height: 100%;

      float: left;
    }
  }
}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
