.home .main {
  background: {
    image: url("../img/bg-home.jpg");
    position: center;
    size: cover;
  }
}

.home__content {
  width: 100%;
  max-width: 500px;
  float: right;

  text-align: center;

  p {
    padding: 0 30px;
  }
}

.home__title {
  padding: 0 15px;
}

.home__button {
  $gap: 5%;
  position: relative;
  margin-top: $gap;

  cursor: pointer;

  &:before {
    content: "";
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    width: 150px;
    height: 1px;

    margin-bottom: $gap/4;

    background: $gray;
  }

  h3 {
    font-weight: normal;
    margin: 0;
  }
}

.popup-modal {
  display: none;
}

.event-takeover {
  img,
  .footer {
    max-width: 900px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .content {
    position: relative;
  }

  .button {
    padding: 25px 0;
    text-align: center;

    background: #f8cad0;

    a {
      border: none;
      outline: none;
      background: none;

      padding: 6px 15px;
      border: 2px solid black;
      color: black;

      font-family: "Arial", serif;
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media all and (max-width: 768px) {
      align-items: start;
    }
  }

  .image {
    flex: 0 0 30%;
    max-width: 30%;

    @media all and (max-width: 768px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  .contact {
    padding-right: 30px;

    @media all and (max-width: 768px) {
      width: 100%;
      padding-right: 0;
    }

    h2 {
      font-size: 30px;
      line-height: 1;
    }

    h4 {
      margin: 0;
    }

    h2,
    p {
      padding: 0;
      margin: 0;
      text-align: center;

      a {
        font-family: "Arial", serif;
        text-align: center;
        color: #b52026;
      }
    }
  }

  .other-logo {
    flex: 0 0 20%;
    max-width: 20%;

    @media all and (max-width: 768px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }

  .contact-text {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.5rem;
    align-items: end;
    justify-content: end;

    margin-bottom: 0.5rem;
  }

  .waze a {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 0.5rem;
    align-items: center;
    justify-content: end;

    img {
      width: 20px;
    }
  }
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet) {
  .home .main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home__content {
  }

  .raya-takeover {
    .button {
      padding: 30px 0;
    }

    .contact {
      padding-right: 0;
    }

    .footer {
      display: block;
      text-align: center;

      .image {
        display: none;
      }
    }
  }
}

.mobile-break {
  display: block; 
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet) {
  .home__content {
    $gap: 2%;

    margin-top: $gap * 2;
    margin-right: $gap;
  }
  .mobile-break {
    display: none !important; 
  }
}

@media all and (min-width: $screen-laptop) {
  .home__content {
    $gap: 4%;

    margin-top: $gap;
    margin-right: $gap * 2;
  }
}

@media all and (min-width: $screen-desktop) {
}

@media all and (min-width: $screen-desktop-large) {
}
