.plans{
  button{
    border: none;
    outline: none;
    background: none;

    padding: 10px;
    padding-top: 6px;
    border: 2px solid $primary-color;
  }

  // Dots slick slider
  .slider__nav {
    display: none;
  }
  
  .slick-dots{
    bottom: -3%;
  }

  // .main.floorplan {
  //   // .plan-floorplan {
  //   //   padding: 2rem;
  //   // }
  //   .plan-box {
  //     width: 90% !important;
  //     margin: auto;
  //   }
  //   .plan-img {
  //     width: 100% !important;
  //     height: auto !important;
  //   }
  //   .slick-dots{
  //     display: none;
  //   }
  // }
}

.plan-img {
  margin-top: 2rem !important;
}

.plans__content{
  @extend .concept__content;
  padding: 10px 15px 65px 15px;
}

.plans__title{
  h4{ margin: 10px 0; }
  h2{ margin: 0; color: $primary-color; }
}

.plans-row{
  display: flex;
  width: 100%;
  height: 65%;
  margin: 15px auto;

  .plan-box{
    width: 50%;
    height: 100%;

    img{
      width: auto !important;
      height: 100% !important;
      margin: 0 auto;
    }
  }
}

.specifications{
  min-width: 500px;
  max-width: 800px;

  table{
    margin: 45px auto;
    width: 100%;
    color: $mine-shaft;

    th{
      font-size: $h5-font-size;
      color: $primary-color;
      text-align: left;
    }

    td{
      font-size: $h6-font-size;
      padding: 2px 0;

      &:first-child{ color: $primary-color; width: 40%; }
      &:nth-child(2){ width: 60%; }
    }

    &.plain{
      td{ color: $mine-shaft; }
    }

    &.rowthree{
      td:nth-child(1){ width: 40%; }
      td:nth-child(2){ width: 30%; }
      td:nth-child(3){ width: 30%; }
    }
  }

  .note{
    display: flex;
    p{ margin: 0; }
    p:first-child{ margin-right: 5px; }
  }
}

.slick-slider{
  -ms-touch-action: initial !important;
  touch-action: initial !important;
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .plans{
    .u_dots{
      &:before, &:after{
        bottom: 10px !important;
      }
    }

    button{
      padding: 5px;
    }
  }

  .plans-row{
    display: block;

    .plan-box{
      width: 100%;
      height: 45%;
      margin-bottom: 10px;
    }
  }

  .slick-dots{
    bottom: -3% !important;
  }

  .main.floorplan {
    .plan-floorplan {
      padding: 0rem !important;
    }
  }

}

.main.floorplan .slick-dots{
  display: none;
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){

  .plans{
    overflow-y: hidden;
  }

  .main.floorplan .plans-row {
    width: 100%;
  }

  .main.floorplan {
    height: auto;
  }
  .main.floorplan .plans__title {
    margin-top: 3rem !important;
  }
  .main.floorplan .plans-row {
    display: flex;
    height: 100%;
  }
  
  .main.floorplan .plan-box {
    width: 90% !important;
    margin: auto;
    padding-left: 4rem;
  }

}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
