.t_white-frame{
  position: relative;
  padding: 3%;

  &:after{
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );

    min-width: 90%;
    min-height: 90%;

    border: 8px solid white;

    pointer-events: none;
  }
}

.t_gold-frame{
  position: relative;
  padding: 2.4% 2.4% 2.4% 0%;

  &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );

    min-width: 95%;
    min-height: 90%;

    border: 2px solid $primary-color;

    pointer-events: none;
  }
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .t_gold-frame{
    padding: 5%;
    
    &:after{
      min-width: 90%;
      min-height: 95%;
    }
  }
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){

}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
