.concept__content{
  position: relative;

  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  text-align: center;

  padding: 15px;
  padding-bottom: 55px;

  h1, h6{ margin: 0; }

}

.concept__flower{
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;

  transform: translate( -50%, -50% );
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .concept__flower{
    display: none;
  }

}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){

}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
