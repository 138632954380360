
// Colors
$aqua-haze: #edf3f5;  // used for backgrounds
$driftwood: #b08850;

$mine-shaft: #3f3f3f;  // used mainly for subheaders
$gray: #888888;        // used for text

$header-color: $driftwood;
$subheader-color: $mine-shaft;
$text-color: $gray;

$primary-color: $driftwood;

/* Font Sizing */
$h1-font-size: 2.6em;
$h2-font-size: 1.7em;
$h3-font-size: 1.5em;
$h4-font-size: 1.3em;
$h5-font-size: 1.1em;
$h6-font-size: 1em;

//Line Gradient
$line-gradient: linear-gradient(to right,  rgba(125,54,50,1) 42%,rgba(125,54,50,0.72) 58%,rgba(125,54,50,0) 100%);

// menu size
$nav-desktop: 145px;
$nav-mobile: 50px;

//media query size
$screen-desktop-large: 1400px;
$screen-desktop: 1200px;
$screen-laptop: 992px;
$screen-tablet: 512px;

// ---------------------------------------------------------------------------------------------------------------------
//  Author: Scotty Vernon
//  www: http://wildflame.co.uk/
// ---------------------------------------------------------------------------------------------------------------------

/* Easing */
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$easeInOutFast: cubic-bezier(1,0,0,1);

$authenticMotion: cubic-bezier(.4,0,.2,1);
