.register .main,
.thank-you .main{
  background: {
    image: url('./../img/bg-leaves.png') !important;
    size: cover;
  }
}

.register__content{
  text-align: center;
  overflow: auto;
  height: 100%;

  padding: 3% 0;

  .container-fluid{
    width: 100%;
    max-width: 800px;
  }
}

.thankyou__content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 50px;

  button{
    background: none;
    outline: none;
    border: none;

    border: 2px solid $primary-color;
    padding: 10px 5px;
    padding-top: 3px;

    font-size: $h5-font-size;
  }
}
