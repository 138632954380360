@font-face {
	font-family: 'Junicode';
	src: local('JunicodeRegular'), local('Junicode-Regular'),
		url('./../fonts/Junicode-Regular.woff2') format('woff2'),
		url('./../fonts/Junicode-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

//Default Font for Everything
*{ font-family: 'Junicode'; }


h1{ color: $primary-color; }
p{ color: $mine-shaft; line-height: 1.75em; }

a{
  &, &:link, &:visited, &:hover, &:active{
    color: currentColor;
    text-decoration: none;
  }
}

h1{font-size: $h1-font-size ;}
h2{font-size: $h2-font-size ;}
h3{font-size: $h3-font-size ;}
h4{font-size: $h4-font-size ;}
h5{font-size: $h5-font-size ;}
h6{font-size: $h6-font-size ;}

h1, h2, h3, h4, h5, h6{
	line-height: 1.2em;
}

p{ font-size: $h6-font-size; max-width: 100%; }
p.fine-text{ font-size: 0.8em;}

// Desktop Above Font Sizing
@media all and(min-width: 992px){
	body{ font-size: 14px; }
}

// Tablet and Mobile
@media all and(max-width: 992px){
	body{ font-size: 12px; }
}
