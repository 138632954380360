body, html{
  position: relative;
  width: 100vw;
  overflow-x: hidden;
}

.main{
  height: 100vh;

  float: right;

  // for animation
  opacity: 0;
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .main{
    width: calc( 100% - #{$nav-mobile});
  }
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){
  .main{
    width: calc( 100% - #{$nav-desktop});
  }
}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
