/*---------------------
      E-Brochure
----------------------*/

.container-brochure {
  margin-top: 2rem;
  text-align: center;
  .row {
    margin-bottom: 5rem;
  }
}

.img-e-brochure {
  max-width: 317px;
  margin-bottom: 1rem;
}

.title-e-brochure {
  color: #b08850;
}

.btn-e-brochure {
  display: inline-block;
  color: black;
  border: 1px solid #b08850;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  background-color: white;
  // font-family: $secondary-font-family;
  margin: 0.5rem 0rem;
  &:hover {
    text-decoration: none !important;
    background-color: #b08850;
    color: white;
    transition: all 0.15s ease-in-out;
  }
  img {
    float: left;
    max-width: 25px;
    padding-right: 0.5rem;
  }
}

.fa-file-pdf {
  // font-size:48px;
  color: red;
}