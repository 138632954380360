/* Slider */

.slick-slider {
    position: relative;
    display: block;
    height: 100%;

    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;

    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-track, .slick-list{ height: 100%; }

.slick-dots{
  list-style: none;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  margin: 15px auto;

  li{
    $size: 10px;
    $gap-between: 4px;

    button{
      // override default button styling
      background: none;
      box-shadow: none;
      outline: none;
      border: none;

      font-size: 0;
      margin: 0 $gap-between;

      &:before{
        display: block;
        content: '';

        width: $size;
        height: $size;
        border-radius: $size/2;

        opacity: 0.7;
        border: 2px solid white;
        cursor: pointer;
      }
    }

    &.slick-active button:before{
      background: white;
      opacity: 1;
    }
  }
}

.slider__nav{
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  bottom: 5%;
  left: 0;

  width: 100%;
  height: 80px;

  padding: 0 10%;
  color: $gray;

  p{
    position: relative;
    top: -10px;
    color: currentColor;

    text-align: center;

    span:first-child{ font-size: $h2-font-size; }
    span:nth-child(2){ margin: 0 5px; }
  }

  &.sn_white{
    color: white;
    bottom: 8%;
  }

  .slick-arrow{
    $width: 170%;
    position: relative;

    background: none;
    outline: none;
    border: none;

    color: currentColor;
    padding: 0;

    span{
      font-size: 1.4em;
      padding: 10px;
    }

    &:before, &:after{
      content: '';
      position: absolute;
      top: 0;

      width: $width;
      height: 1px;
      background: currentColor;
    }

    &:after{
      height: 2px;
      background: $primary-color;
      max-width: 0;
      transition: max-width 550ms $easeInOutQuart;
    }

    &.slick-prev{
      &:before, &:after{ left: 0; }
    }

    &.slick-next{
      &:before, &:after{ right: 0; }
    }

    &:hover{
      &:after{ max-width: 200%; }
    }
  }
}
