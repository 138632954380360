.contact__content{
  @extend .concept__content;

  p:not(:first-of-type){ margin: 0; }
  p:nth-of-type(2){ margin-top: 40px; }
}

.contact__flower{
  @extend .concept__flower;
}


.contact__background.bg-box{
  width: 100%;
  height: 100%;

  background: {
    image: url('./../img/bg-contact.jpg');
    size: cover;
    position: center;   
  }
}
