.location{
  .icon-group{
    display: flex;
    justify-content: space-between;

    .icon{
      margin: 0 15px;
    }
  }
}

.location__content{
  @extend .concept__content;
  padding: 5%;
}

.location__flower{
  @extend .concept__flower;

  height: 100%;
  width: 100%;
}

.location__map{
  width: 100%;
  height: 100%;

  padding: 3%;

  img{
    max-width: 100%;
  }
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .location .main{
    overflow: auto;
    height: 100%;

    background: {
      image: url('./../img/bg-leaves.png') !important;
      size: cover;
    }
  }

  .location__content,
  .location__map{
    // override split mobile styling
    position: static !important;
    height: initial !important;

    padding: 10%;
  }

  .location__flower{ display: none; }

}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){
  .location__flower{
    img{
      max-height: 100%;
    }
  }

}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
