.splash{
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  background: {
    color: $aqua-haze;
    image: url('./../img/bg-flower-scattered.png');
    size: cover;
  }

  animation: {
    name: fadeOut;
    duration: 800ms;
    delay: 1000ms;
    fill-mode: both;
    timing-function: $easeOutQuad;
  }

  &:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );

    width: 95%;
    height: 95%;

    border: 10px solid rgba( white, 0.8);

  }

  .logo-box{
    img{
      max-width: 250px;
    }
  }

  @keyframes fadeOut{
    0% {opacity: 1; }
    100% {opacity: 0; }
  }
}

.loader{
  position: fixed;
  z-index: 998;

  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  background: {
    color: $aqua-haze;
    image: url('./../img/bg-flower-scattered.png');
    size: cover;
  }

  pointer-events: none;

  h4{ color: $primary-color; }
}

@media all and(max-width: $screen-tablet){
  .loader{
    width: calc( 100% - #{$nav-mobile});
  }
}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){
  .loader{
    width: calc( 100% - #{$nav-desktop});
  }
}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
