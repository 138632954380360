.container-phases {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding-left: 11rem;
  select {
    height: 35px;
    width: 250px;
    padding: 0 5px;
    border: 2px solid #b08850;
    background: transparent;
    font-size: 1em;
    &:hover, &.active, &.focus-visible {
      border: 2px solid #b08850 !important; 
    }
  }
}

.container-phase iframe {
  margin: 3% 7%;
}

.container-phase-title.brochure { 
  padding-left: 2rem !important;
}

.container-phase-title {
 text-align: center;
 padding-left: 8rem;
  h2 {
  color: #b08850;
  margin: 5rem 0rem 0rem;
 }
} 
@media only screen and (min-width: 600px) {
  .container-phase-title.brochure {
    padding-left: 1rem !important;
  }
  .container-phase-title {
    position: relative;
    display: flex;
    justify-content: center;
    padding-left: 11rem;
  }
}

.container-phase-title h1 {
  margin-bottom: 0px;
}

.slick-cloned {
  display: none;
}

.gallery__slider{
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 20px solid white;

    pointer-events: none;
  }
}

.gallery__content{
  position: relative;

  display: flex !important;
  justify-content: center;
  align-items: flex-end;

  p{
    font-size: $h2-font-size;
    color: white;
    margin-bottom: 3%;
  }

  .image-box{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    @for $num from 1 through 100{
      &[data-image='#{$num}']{
        background: {

          @if $num < 10{
            image: url('./../img/gallery-img-0#{$num}.jpg');
          }@else{
            image: url('./../img/gallery-img-#{$num}.jpg')
          }

          size: cover;
          position: center;
        }
      }
    }
  }
}

// no slider styling
.gallery.no-slider{

  .gallery__slider{
    height: 100%;
  }

  .gallery__content{
    position: relative;
    height: 16.6667%;
    height: calc( 100% / 6 );

    align-items: center;

    &:before{
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: rgba(black, 0.3);
    }

    p{ position: relative; z-index: 2; }
  }

  .modal-content{
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.slider__nav.sn_white {
  display: none;
}

.slick-dots{
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100%;
  height: 96px;
  padding: 0 36%;
  color: #b08850 !important;
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  background-color: #b08850 !important;
}

.slick-dots li button:before {
  border: 2px solid #b08850 !important;
}

.property-logo {
  width: 141px;
  padding-top: 3rem;
}

.disclaimer {
  position: absolute;
  bottom: 0.3%;
  font-size: 0.8em !important;
  color: #EFEDE6;
}

.disclaimer.right.concept {
  right: 1.5%;
}

.disclaimer.left {
  left: 3.5%;
  opacity: 0;
}

.disclaimer.right {
  right: 3.5%;
  opacity: 1;
}

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .gallery{
    .main{
      // min-height: 100vh;
      height: initial;
    }
  }
  .modal-content{
    padding: 2rem !important;
  }
}

@media only screen and (max-width: 767px)  {
  .gallery .main {
    // {
      // min-height: 100vh;
      height: auto !important;
    // }
  }
}


// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){
}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
