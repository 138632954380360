.u_dots{
  $dot-size: 6px;

  display: inline-block;
  position: relative;

  &:before, &:after{
    content: '';
    position: absolute;
    bottom: 20%;

    display: block;
    width: $dot-size;
    height: $dot-size;
    border-radius: $dot-size/2;

    background: currentColor;
  }
  &:before{ left: -$dot-size*3; }
  &:after{ right: -$dot-size*3; }
}

.u_bg-aqua{ background: $aqua-haze; }

// ==========================================
// Mobile
// ==========================================
@media all and(max-width: $screen-tablet){
  .u_bg-aqua{ background: rgba( $aqua-haze, 0.8); }

  .u_dots{
    &:before, &:after{
      bottom: 20px;
    }
  }

}

// ==========================================
// ==========================================

@media all and (min-width: $screen-tablet){

}

@media all and (min-width: $screen-laptop){

}

@media all and (min-width: $screen-desktop){

}

@media all and (min-width: $screen-desktop-large){

}
