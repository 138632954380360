.modal {
  display: none;
}

[data-modal]{
  cursor: pointer;
}

.modal-opener{
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
}

.vanilla-modal .modal {
  display: none;

  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s, z-index 0s 0.2s;
  text-align: center;
  overflow: hidden;

  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.vanilla-modal .modal > * {
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  text-align: left;
}

.vanilla-modal .modal:before {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.vanilla-modal.modal-visible .modal {
  display: block;
  z-index: 99999;
  opacity: 1;
  transition: opacity 0.4s;

  pointer-events: all;
}

.modal-inner {
  position: relative;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  overflow-x: auto;
  overflow-y: auto;
  background: #fff;
  z-index: -1;
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 0.4s, transform 0.4s;
  transition-timing-function: $easeOutQuart;
}
.modal-visible .modal-inner {
  z-index: 100;
  opacity: 1;
  transform: translateY(0);
}

.modal-content{
  padding: 15px;
}

[data-modal-close] {
  position: fixed;
  z-index: 2;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  font-family: arial;
  line-height: 25px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  color: black;
  box-shadow: -1px 1px 2px rgba(0,0,0,0.2);
}
